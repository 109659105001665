import React, { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import logo from "../assets/img/Acoer_Icon.svg";
import { bindActionCreators } from "redux";
import * as userActions from "../components/User/UserActions";
import { connect } from "react-redux";
import { AppContext } from "../App";
import Typography from "@material-ui/core/Typography";

export const LoginView = (props) => {
  const { userActions } = props;
  const { buildAuthorizeUrl, loginWithRedirect } = useAuth0();
  const { platform } = useContext(AppContext);

  const login = async () => {
    if (platform === "web") {
      loginWithRedirect();
    } else {
      // Ask auth0-react to build the login URL
      const url = await buildAuthorizeUrl();

      // Redirect using Capacitor's Browser plugin
      await Browser.open({ url });
    }
  };

  useEffect(() => {
    userActions.resetUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!platform) return null;
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
      direction={"column"}
      style={{
        backgroundColor: "#0b0b0b",
        height: "100vh",
      }}
    >
      <Grid item>
        <img
          src={logo}
          alt=""
          style={{
            maxWidth: "90%",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 10,
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          variant={"h3"}
          style={{ fontWeight: "bold", color: "#FFDB58" }}
        >
          HealthToken
        </Typography>
      </Grid>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item style={{ marginTop: 40 }}>
          <Button
            onClick={login}
            variant={"contained"}
            style={{ backgroundColor: "#FFDB58" }}
            disableElevation
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(LoginView);
