import React from "react";

//components
import DatePicker from "../Shared/Forms/Fields/DatePicker";
import BooleanInput from "../Shared/Forms/Fields/BooleanInput";

// mui
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import SharedStyles from "../../assets/jss/components/SharedStyles";
import DropdownInput from "../Shared/Forms/Fields/DropdownInput";

const ClinicalTrialsForm = (props) => {
  const { records, setRecords, classes, dependentView } = props;

  const handleChange = (event, type) => {
    let value = event.target.value;
    if (value === "yes") {
      value = true;
    } else {
      value = false;
    }
    setRecords({
      ...records,
      [type]: value,
    });
  };

  const handleDropdownChange = (e, type) => {
    let value = e.target.value;
    setRecords({
      ...records,
      [type]: value,
    });
  };

  const handleDateChange = (event) => {
    setRecords({
      ...records,
      clinicalTrialEndDate: event,
    });
  };

  return (
    <Grid container justifyContent={"center"} spacing={3}>
      <Grid item xs={11} style={{ marginTop: 10, marginBottom: 10 }}>
        <DropdownInput
          field={{
            type: "dropdown",
            name: "clinicalTrial",
            question: `${
              dependentView ? "Has your dependent" : "Have you"
            } participated in one or more clinical research studies for new therapies or treatments within the last
            12 months?`,
            options: ["YES", "NO", "UNDECIDED"],
          }}
          handleChange={(e) => handleDropdownChange(e, "clinicalTrial")}
          value={records.clinicalTrial || null}
          required={true}
          currentData={records}
          multiSelect={false}
        />
      </Grid>
      {records.clinicalTrial === "YES" && [
        <Grid item xs={12} style={{ backgroundColor: "#EBEBEB" }}>
          <Typography>
            Please let us know the end date of the current clinical research{" "}
            {dependentView ? "your dependent is " : "you are "}
            participating in so we know when {dependentView
              ? "they"
              : "you"}{" "}
            are available for further clinical research participation.
          </Typography>
        </Grid>,
        <Grid item xs={11}>
          <DatePicker
            label={"Please add the end date"}
            value={records.clinicalTrialEndDate || Date.now()}
            handleChange={handleDateChange}
          />
        </Grid>,
      ]}
      <Grid item xs={11}>
        <Typography className={classes.title} style={{ marginTop: "20px" }}>
          Would {dependentView ? "your dependent" : "you"} be interested in
          participating in a clinical research study for new treatments or
          therapies?
        </Typography>
      </Grid>
      <Grid item>
        <BooleanInput
          value={records.clinicalTrialEnrollment}
          handleChange={(e) => handleChange(e, "clinicalTrialEnrollment")}
          name={"boolean"}
        />
      </Grid>
      {records.clinicalTrialEnrollment && (
        <Grid item xs={12} style={{ backgroundColor: "#BFE3B4" }}>
          <Typography>
            Thank you for your interest in participating in clinical research.
            You will be contacted by the HealthToken team if you are selected as
            a potential participant from researchers.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(SharedStyles)(ClinicalTrialsForm);
