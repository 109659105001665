import React, { useContext } from "react";
import { termsHelper } from "./helpers/TermsHelper";

//material-ui
import { Checkbox, Grid, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import OnboardingStyles from "../../assets/jss/components/OnboardingStyles";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import { AppContext } from "../../App";

const Terms = (props) => {
  const {
    classes,
    checkedTerms,
    checkedDependent,
    setCheckedDependent,
    setCheckedTerms,
    name,
    setName,
    consentDate,
    type,
    dependentView,
  } = props;
  return (
    <Grid container>
      {type === "onboarding" && (
        <Grid
          item
          xs={12}
          className={classes.formBanner}
          style={{ backgroundColor: "#0b0b0b" }}
        >
          <Typography variant="h1" style={{ marginTop: "20px" }}>
            HealthToken™ Platform Patient Terms and Conditions
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classes.consentContainer}>
        <div
          dangerouslySetInnerHTML={{
            __html: termsHelper && termsHelper[0].value,
          }}
        />
        {type === "onboarding" && (
          <Grid item xs={12} className={classes.consentAction}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Full Name"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  inputProps={{
                    "data-testid": `textField.consentName`,
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "30px" }}>
                <TextField
                  variant="outlined"
                  label="Today's Date"
                  defaultValue={consentDate}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                className={classes.consentCheck}
                alignItems={"center"}
              >
                <Grid item xs={2}>
                  <Checkbox
                    checked={checkedTerms}
                    onChange={() => setCheckedTerms(!checkedTerms)}
                    style={{ color: "#097969" }}
                    inputProps={{
                      "data-testid": `consent.checkbox`,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  onClick={() => setCheckedTerms(!checkedTerms)}
                >
                  <Typography>
                    I have read the data usage terms from HealthToken
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {dependentView && (
              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.consentCheck}
                  alignItems={"center"}
                >
                  <Grid item xs={2}>
                    <Checkbox
                      checked={checkedDependent}
                      onChange={() => setCheckedDependent(!checkedDependent)}
                      style={{ color: "#097969" }}
                      inputProps={{
                        "data-testid": `consent.checkbox`,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    onClick={() => setCheckedDependent(!checkedDependent)}
                  >
                    <Typography>
                      I have permission or assent from the dependent to register
                      them for the purpose of this app.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(OnboardingStyles)(Terms);
