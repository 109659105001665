import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserShield,
  faDatabase,
  faGift,
  faLock,
  faShieldAlt,
  faBrain,
} from "@fortawesome/free-solid-svg-icons";
import heroImage from "../assets/img/hero_image.svg";

//components
import logo from "../assets/img/Acoer_Icon.svg";

// mui
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const BrowserView = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const features = [
    {
      icon: faUserShield,
      title: "Consent",
      description: "User management of consent with transparency and control.",
      color: "#FFDB58",
    },
    {
      icon: faDatabase,
      title: "Data",
      description: "Secure, share, and manage decentralized health data.",
      color: "#FFDB58",
    },
    {
      icon: faGift,
      title: "Rewards",
      description: "Incentivize engagement with tokenized rewards.",
      color: "#FFDB58",
    },
    {
      icon: faLock,
      title: "Privacy",
      description: "Ensure user privacy with secure data handling.",
      color: "#FFDB58",
    },
    {
      icon: faShieldAlt,
      title: "Security",
      description:
        "Use of DLT ensures security, traceability, and transparency.",
      color: "#FFDB58",
    },
    {
      icon: faBrain,
      title: "AI Insights",
      description: "Leverage AI for actionable health insights.",
      color: "#FFDB58",
    },
  ];

  return (
    <Grid
      container
      style={{
        minHeight: "100vh",
        backgroundColor: "#0D0D0D",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: isMobile ? "16px" : "32px",
      }}
    >
      <Grid item xs={12}>
        <Grid
          container
          spacing={isMobile ? 2 : 6}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  justifyContent={isMobile ? "center" : "flex-start"}
                >
                  <Grid item>
                    <img
                      src={logo}
                      alt="HealthToken.ai Logo"
                      style={{
                        maxWidth: "100%",
                        maxHeight: isMobile ? 60 : 80,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant={isMobile ? "h4" : "h2"}
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        color: "#FFDB58",
                        textAlign: isMobile ? "center" : "left",
                      }}
                    >
                      HealthToken
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  variant={isMobile ? "body1" : "h4"}
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    color: "#FFF",
                    textAlign: isMobile ? "center" : "left",
                  }}
                >
                  Platform to tokenize health data and improve patient outcomes
                  through AI and decentralized technologies.
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{
                    fontFamily: "Inter",
                    color: "#CCC",
                    textAlign: isMobile ? "center" : "left",
                  }}
                >
                  HealthToken enables seamless consent management, data sharing,
                  and tokenized rewards, transforming healthcare data and
                  participation with decentralized technology, AI and DePIN.
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: isMobile ? "center" : "left" }}>
                <a
                  href="https://my.healthtoken.ai"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#FFDB58",
                      color: "#000",
                      fontWeight: "bold",
                      marginBottom: isMobile ? 15 : "0",
                    }}
                  >
                    Get Started
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={heroImage}
              alt="HealthToken.ai Platform"
              style={{
                maxWidth: "100%",
                borderRadius: "8px",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              textAlign: "center",
              marginTop: isMobile ? 20 : 100,
              backgroundColor: "#2D2D2D",
            }}
          >
            <Grid
              container
              spacing={4}
              justifyContent="center"
              alignItems={"center"}
              alignContent={"center"}
            >
              {features.map((feature, index) => (
                <Grid item xs={6} sm={2} key={index}>
                  <Grid
                    container
                    direction={"column"}
                    justifyContent={"center"}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <FontAwesomeIcon
                        icon={feature.icon}
                        size="4x"
                        style={{
                          color: feature.color,
                          transition: "transform 0.3s ease",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "scale(1.2)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          marginTop: "8px",
                          color: "#FFF",
                          fontWeight: "bold",
                        }}
                      >
                        {feature.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{ marginTop: "4px", color: "#CCC" }}
                      >
                        {feature.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BrowserView;
