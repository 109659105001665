import * as types from "./TokenEngineActions";

export const userToken = (state = [], action) => {
  switch (action.type) {
    case types.CREATE_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        userTokenLoading: true,
      };

    case types.CREATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        userToken: action.payload,
        userTokenLoading: false,
      };

    case types.CREATE_TOKEN_CONSENT_REQUEST:
      return {
        ...state,
        userTokenConsentLoading: true,
      };

    case types.CREATE_TOKEN_CONSENT_SUCCESS:
      return {
        ...state,
        userTokenConsent: action.payload,
        userConsentStatus: { hasConsent: true },
        userTokenConsentLoading: false,
      };

    case types.SEND_INCENTIVE_REQUEST:
      return {
        ...state,
        sendingIncentive: true,
      };

    case types.SEND_INCENTIVE_SUCCESS:
      return {
        ...state,
        userIncentive: action.payload,
        sendingIncentive: false,
      };

    case types.GET_INCENTIVE_BALANCE_REQUEST:
      return {
        ...state,
        incentiveBalanceLoading: true,
      };

    case types.GET_INCENTIVE_BALANCE_SUCCESS:
      return {
        ...state,
        userIncentiveBalance: action.payload,
        incentiveBalanceLoading: false,
      };

    case types.REDEEM_INCENTIVE_BALANCE_REQUEST:
      return {
        ...state,
        incentiveRedeemLoading: true,
      };

    case types.REDEEM_INCENTIVE_BALANCE_SUCCESS:
      return {
        ...state,
        userIncentiveBalance: action.payload,
        incentiveRedeemLoading: false,
      };

    case types.GET_USER_CONSENT_STATUS_REQUEST:
      return {
        ...state,
        consentStatusLoading: true,
      };

    case types.GET_USER_CONSENT_STATUS_SUCCESS:
      return {
        ...state,
        userConsentStatus: action.payload,
        consentStatusLoading: false,
      };

    case types.WITHDRAW_CONSENT_REQUEST:
      return {
        ...state,
        consentWithdrawLoading: true,
      };

    case types.WITHDRAW_CONSENT_SUCCESS:
      return {
        ...state,
        userConsentStatus: { hasConsent: false },
        consentWithdrawLoading: false,
      };

    default:
      return state;
  }
};
