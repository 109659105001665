export class CryptoService {
  ALGORITHM = "AES-GCM";
  KEY_LENGTH = 256; // Bits
  IV_LENGTH = 12; // Bytes
  SALT_LENGTH = 16; // Bytes
  TAG_LENGTH = 16; // Bytes (implicit in AES-GCM)

  async encryptPrivateKey(privateKey, password) {
    try {
      // Generate a random salt
      const salt = crypto.getRandomValues(new Uint8Array(this.SALT_LENGTH));

      // Derive key using PBKDF2
      const key = await this.deriveKey(password, salt);

      // Generate a random IV
      const iv = crypto.getRandomValues(new Uint8Array(this.IV_LENGTH));

      // Encrypt the private key
      const encodedData = new TextEncoder().encode(privateKey);
      const encrypted = await crypto.subtle.encrypt(
        {
          name: this.ALGORITHM,
          iv: iv,
        },
        key,
        encodedData
      );

      // Combine salt + iv + encrypted data
      const combined = new Uint8Array(
        salt.byteLength + iv.byteLength + encrypted.byteLength
      );
      combined.set(salt, 0);
      combined.set(iv, salt.byteLength);
      combined.set(new Uint8Array(encrypted), salt.byteLength + iv.byteLength);

      return btoa(String.fromCharCode(...combined));
    } catch (error) {
      console.error("Encryption error:", error);
      throw error;
    }
  }

  async decryptPrivateKey(encryptedData, password) {
    try {
      const combined = Uint8Array.from(atob(encryptedData), (c) =>
        c.charCodeAt(0)
      );

      // Extract parts
      const salt = combined.slice(0, this.SALT_LENGTH);
      const iv = combined.slice(
        this.SALT_LENGTH,
        this.SALT_LENGTH + this.IV_LENGTH
      );
      const encrypted = combined.slice(this.SALT_LENGTH + this.IV_LENGTH);

      // Derive key using PBKDF2
      const key = await this.deriveKey(password, salt);

      // Decrypt the private key
      const decrypted = await crypto.subtle.decrypt(
        {
          name: this.ALGORITHM,
          iv: iv,
        },
        key,
        encrypted
      );

      return new TextDecoder().decode(decrypted);
    } catch (error) {
      console.error("Decryption error:", error);
      throw error;
    }
  }

  async deriveKey(password, salt) {
    // Encode the password as UTF-8
    const encodedPassword = new TextEncoder().encode(password);

    // Import the password as a key for PBKDF2
    const baseKey = await crypto.subtle.importKey(
      "raw",
      encodedPassword,
      { name: "PBKDF2" },
      false,
      ["deriveKey"]
    );

    // Derive an AES-GCM key from the password and salt
    return crypto.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt: salt,
        iterations: 100000,
        hash: "SHA-256",
      },
      baseKey,
      { name: "AES-GCM", length: this.KEY_LENGTH },
      false,
      ["encrypt", "decrypt"]
    );
  }

  async calculateSHA256(input) {
    // Encode the input string as a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(input);

    // Calculate the SHA-256 hash
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);

    // Convert the hash to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  }
}

export const cryptoService = new CryptoService();
