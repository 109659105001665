import * as types from "./UserActions";

export const user = (state = [], action) => {
  switch (action.type) {
    case types.GET_USER_REQUEST:
      return {
        ...state,
        userGetLoading: true,
      };

    case types.GET_USER_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        userSavedTrials: action.payload.config.savedClinicalTrials,
        userGetLoading: false,
      };

    case types.GET_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        userGetLoading: false,
      };

    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        userLoading: true,
      };

    case types.UPDATE_USER_SUCCESS:
      if (action.payload.serialNumber) {
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            metadata: {
              ...state.userDetails.metadata,
              consentSerialNumber: action.payload.serialNumber,
            },
          },
        };
      }
      return {
        ...state,
        userDetails: action.payload,
        userSavedTrials: action.payload.config.savedClinicalTrials,
        userLoading: false,
      };

    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        userLoading: false,
      };

    case types.GET_USER_PROFILE_PIC_REQUEST:
      return {
        ...state,
        userProfilePicLoading: true,
      };

    case types.GET_USER_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        userProfilePic: action.payload,
        userProfilePicLoading: false,
      };

    case types.GET_USER_PROFILE_PIC_FAILURE:
      return {
        ...state,
        error: action.error,
        userProfilePicLoading: false,
      };

    case types.UPLOAD_USER_PIC_REQUEST:
      return {
        ...state,
      };

    case types.UPLOAD_USER_PIC_SUCCESS:
      return {
        ...state,
        userProfilePic: action.payload,
      };

    case types.UPLOAD_USER_PIC_FAILURE:
      return {
        ...state,
        error: action.error,
        userProfilePicLoading: false,
      };

    case types.GET_USER_REWARDS_REQUEST:
      return {
        ...state,
        rewardsLoading: true,
      };

    case types.GET_USER_REWARDS_SUCCESS:
      return {
        ...state,
        rewardsLoading: false,
        initialRewards: action.payload,
      };

    case types.GET_USER_REWARDS_FAILURE:
      return {
        ...state,
        error: action.error,
        rewardsLoading: false,
      };

    case types.GET_TERMS_REQUEST:
      return {
        ...state,
        termsLoading: true,
      };

    case types.GET_TERMS_SUCCESS:
      return {
        ...state,
        termsLoading: false,
        termsWording: action.payload,
      };

    case types.GET_TERMS_FAILURE:
      return {
        ...state,
        error: action.error,
        termsLoading: false,
      };

    case types.UPDATE_CLINICAL_TRIAL_REQUEST:
      return {
        ...state,
        updateTrialLoading: true,
        loadingID: action.payload,
      };

    case types.UPDATE_CLINICAL_TRIAL_SUCCESS:
      return {
        ...state,
        updateTrialLoading: false,
        userSavedTrials: action.payload,
      };

    case types.RESET_USER_DETAILS:
      return {};

    default:
      return state;
  }
};
