import page1 from "../../../assets/img/Squareshape1.svg";
import page2 from "../../../assets/img/Squareshape2.svg";
import page3 from "../../../assets/img/Squareshape3.svg";

export const steps = {
  about: [
    {
      page: 1,
      type: "about",
      header: "Why Us",
      text:
        "We are committed to establishing trust in advocating for equal partnership between patient and clinical researcher using our technology and innovation to transform how therapeutics are developed for us, the patients.",
      background: page1,
      color: "#E8C658",
    },
    {
      page: 2,
      type: "about",
      header: "What We Do",
      text:
        "We are transforming clinical research study design and recruitment using a patient-driven approach to accelerate research and improve patient outcomes using the  patient’s genetic and therapeutic profile.",
      background: page2,
      color: "#E8C658",
    },
    {
      page: 3,
      type: "about",
      header: "What's in it for You?",
      text:
        "Empowering you to choose how and who uses your health data for your own benefit, making it intuitive, and transparent.",
      button: "Got it!",
      background: page3,
      color: "#E8C658",
    },
  ],
  info: [
    {
      page: 1,
      type: "form",
      header: "Profile Details",
      button: "Continue",
    },
    {
      page: 2,
      type: "form",
      header: "Profile Details",
      button: "Next",
    },
    {
      page: 3,
      type: "form",
      header: "",
      button: "I Agree",
    },
    {
      page: 4,
      type: "form",
      header: "Medical Records",
      button: "Continue",
    },
  ],
};

export const relationships = [
  {
    value: "Parent",
    label: "Parent",
  },
  {
    value: "Spouse",
    label: "Spouse",
  },
  {
    value: "Child",
    label: "Child",
  },
  {
    value: "Friend",
    label: "Friend",
  },
  {
    value: "Other",
    label: "Other",
  },
];
