import React, { useState, useEffect, createContext } from "react";
import { Switch, Route } from "react-router-dom";
import { Network } from "@capacitor/network";
import { Device } from "@capacitor/device";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "./components/User/UserActions";
import * as paymentsActions from "./components/Payments/PaymentsActions";

// components
import theme from "./styles/theme";
import Notification from "./components/Notifications/Notification";
import Confirmation from "./components/Confirmation/Confirmation";
import OnboardingView from "./views/OnboardingView";
import OfflineNotification from "./components/Notifications/OfflineNotification";
import Routes from "./Routes";
import logo from "./assets/img/Acoer_Icon.svg";
import { sec } from "./security";

// MUI
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Grid,
  Typography,
  Box,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

//Auth0
import { useAuth0 } from "@auth0/auth0-react";
import LoginView from "./views/LoginView";

// Capacitor
import { App as CapApp } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import history from "./components/Shared/Redux/history";
import config from "./auth.config";
import SplashView from "./views/SplashView";

export const AppContext = createContext(undefined);
const App = (props) => {
  const muiTheme = useTheme();
  const { userDetails, userProfilePic, userActions, termsWording } = props;
  const {
    isLoading,
    user,
    isAuthenticated,
    handleRedirectCallback,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
    buildLogoutUrl,
  } = useAuth0();
  const [firstLogin, setFirstLogin] = useState(true);
  const [online, setOnline] = useState(true);
  const [deviceOs, setDeviceOs] = useState("unknown");
  const [progress, setProgress] = useState(0);
  const [platform, setPlatform] = useState("");
  const desktop = useMediaQuery(muiTheme.breakpoints.up("sm"));
  sec.setAccessTokenSilently(getAccessTokenSilently);
  sec.setIdTokenClaims(getIdTokenClaims);

  // lock orientation to portrait only
  // window.screen.orientation.lock("portrait");

  // get device info
  useEffect(() => {
    const logDeviceInfo = async () => {
      const info = await Device.getInfo();
      const platform = Capacitor.getPlatform();
      setDeviceOs(info.operatingSystem);
      setPlatform(platform);
    };
    logDeviceInfo();
  }, []);

  // add listener to update online status to show offline notification
  Network.addListener("networkStatusChange", (status) => {
    console.log("Network status changed", status);
    if (status && status.connected && typeof status.connected === "boolean") {
      setOnline(status.connected);
    }
  });

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener("appUrlOpen", async ({ url }) => {
      if (
        url.includes("state") &&
        (url.includes("code") || url.includes("error"))
      ) {
        await handleRedirectCallback(url);
      }
      // No-op on Android
      await Browser.close();
    });
  }, [handleRedirectCallback]);

  useEffect(() => {
    if (user) {
      if (!userDetails) {
        userActions.getUser(user.email);
        userActions.getUserProfilePic(user.email);
        userActions.getTermsWording();
      }
      if (userDetails) {
        let firstLogin = userDetails.config.firstTimeLogin;
        if (firstLogin === null) {
          firstLogin = true;
        }
        setFirstLogin(firstLogin);
        // paymentsActions.getUserBalance(
        //   userDetails.dependant ? userDetails.id : null
        // );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated, userDetails, userProfilePic]);

  useEffect(() => {
    if (user && isAuthenticated) {
      userActions.userPing();
    }
  }, [user, isAuthenticated, userActions]);

  useEffect(() => {
    if (isAuthenticated) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          let newValue = oldProgress;
          if (oldProgress < 95) {
            const diff = Math.random() * 10;
            let loadPercentage = oldProgress + diff;
            if (loadPercentage > 95) {
              newValue = 95;
            } else {
              newValue = oldProgress + diff;
            }
          }
          return Math.min(newValue, 100);
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isLoading, userDetails, isAuthenticated]);

  useEffect(() => {
    if (!isLoading) {
      setProgress(100);
    }
  }, [isLoading]);

  const logoutUserTokens = async () => {
    if (platform === "web") {
      logout({ returnTo: window.location.origin });
    } else {
      history.push("/");
      // Open the browser to perform a logout
      await Browser.open({
        url: buildLogoutUrl({
          returnTo: config.redirectUri,
          client_id: config.clientId,
        }),
        windowName: "_self",
      });
      // Ask the SDK to log out locally, but not do the redirect
      await logout({ localOnly: true });
    }
    setProgress(0);
  };

  if (!window.location.href.includes("my")) {
    // hideSplashScreen();
    return (
      <MuiThemeProvider theme={theme}>
        <AppContext.Provider
          value={{
            userDetails,
            online,
            deviceOs,
            logoutUserTokens,
            platform,
            desktop,
          }}
        >
          <CssBaseline />
          <Notification />
          <Confirmation />
          <OfflineNotification />
          <div>
            <Switch>
              <Route
                exact
                path="/"
                component={(props) => <SplashView {...props} />}
              />
            </Switch>
          </div>
        </AppContext.Provider>
      </MuiThemeProvider>
    );
  }

  // if user is not authenticated, show login page
  if (!isAuthenticated) {
    // hideSplashScreen();
    return (
      <MuiThemeProvider theme={theme}>
        <AppContext.Provider
          value={{
            userDetails,
            online,
            deviceOs,
            logoutUserTokens,
            platform,
            desktop,
          }}
        >
          <CssBaseline />
          <Notification />
          <Confirmation />
          <OfflineNotification />
          <div>
            <Switch>
              <Route
                exact
                path="/"
                component={(props) => <LoginView {...props} />}
              />
            </Switch>
          </div>
        </AppContext.Provider>
      </MuiThemeProvider>
    );
  }

  // show loading progress while fetching user data
  if (isAuthenticated && (!userDetails || !userDetails.id)) {
    function LinearProgressWithLabel(props) {
      return (
        <Box display="flex" alignItems="center">
          <Box width={300} mr={1}>
            <LinearProgress
              color={"primary"}
              variant="determinate"
              {...props}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              props.value
            )}%`}</Typography>
          </Box>
        </Box>
      );
    }

    return (
      <div>
        {online && (
          <MuiThemeProvider theme={theme}>
            <AppContext.Provider
              value={{
                userDetails,
                online,
                deviceOs,
                logoutUserTokens,
                platform,
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
                direction={"column"}
                style={{
                  position: "fixed",
                  top: "30vh",
                }}
              >
                <Grid item xs={7} md={2}>
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "100%" }}
                    id={"loadingProgress"}
                    height={200}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LinearProgressWithLabel value={progress} />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography color={"primary"}>
                    Loading... please wait
                  </Typography>
                </Grid>
              </Grid>
            </AppContext.Provider>
          </MuiThemeProvider>
        )}
      </div>
    );
    // if user is authenticated but first time login, show onboarding
  }

  if (
    isAuthenticated &&
    userDetails &&
    userDetails.config &&
    firstLogin &&
    !userDetails.dependant
  ) {
    // hideSplashScreen();
    return (
      <AppContext.Provider
        value={{
          userDetails,
          online,
          deviceOs,
          logoutUserTokens,
          platform,
          termsWording,
          desktop,
        }}
      >
        <OnboardingView setFirstLogin={setFirstLogin} />
      </AppContext.Provider>
    );
  }
  // if user is authenticated and not first time login, show app
  if (isAuthenticated && userDetails && userDetails.id) {
    // hideSplashScreen();
    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <AppContext.Provider
            value={{
              userDetails,
              online,
              deviceOs,
              logoutUserTokens,
              platform,
              termsWording,
              desktop,
              // userBalance,
            }}
          >
            <CssBaseline />
            <Notification />
            <Confirmation />
            <OfflineNotification online={online} />
            <Routes firstLogin={firstLogin} />
          </AppContext.Provider>
        </MuiThemeProvider>
      </div>
    );
  }
};

export function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails,
    userGetLoading: state.user.userGetLoading,
    userProfilePic: state.user.userProfilePic,
    termsWording: state.user.termsWording,
    // userBalance: state.balance.userBalance,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    paymentsActions: bindActionCreators(paymentsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
