import React, { useEffect } from "react";

//redux
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../User/UserActions";
import * as dependentActions from "../Dependents/DependentsActions";
import * as recordsActions from "../Records/RecordsActions";

//swipable-views
import SwipeableViews from "react-swipeable-views";

//components
import OnboardingPage from "./OnboardingPage";
import AboutPage from "./AboutPage";

//material-ui
import { withStyles, useTheme } from "@material-ui/core/styles";
import OnboardingStyles from "../../assets/jss/components/OnboardingStyles";
import { Button, MobileStepper } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const Onboarding = (props) => {
  let {
    steps,
    classes,
    setFirstLogin,
    activeStep,
    setActiveStep,
    handleStepChange,
    // userActions,
    userDetails,
    dependentActions,
    handleBack,
    handleNext,
    closeDependentDialog,
    dependents,
    recordsActions,
    handleAcceptedTc,
    setDependentData,
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (dependents && dependents.length && setDependentData) {
      setDependentData(dependents[0]);
    }
  }, [dependents]);

  //sets user's first login to false and sends update to backend
  const handleFirstLogin = (userInfo) => {
    userInfo.config.firstTimeLogin = false;
    dispatch(userActions.updateUser(userInfo));
    setFirstLogin(false);
  };

  //handles creation of dependent
  const handleDependentCreation = (dependentInfo) => {
    dependentActions.addDependent(dependentInfo);
  };

  //handles consent of user
  const handleConsent = (wordingKey, actAs) => {
    userActions.newUserConsent(wordingKey, actAs);
  };

  if (activeStep < 3) {
    return (
      <div>
        <SwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          data-testid={"swipeable-view"}
        >
          {steps.about.map((step, index) => (
            <AboutPage key={index} step={step} setActiveStep={setActiveStep} />
          ))}
        </SwipeableViews>
        <MobileStepper
          steps={3}
          variant={"dots"}
          activeStep={activeStep}
          position={"bottom"}
          classes={{
            dots: classes.dots,
            dot: classes.dot,
            dotActive:
              activeStep === 1 ? classes.dotActiveAlternate : classes.dotActive,
            root: classes.root,
          }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === 2}
              variant={"contained"}
              style={{
                backgroundColor: activeStep === 2 ? "#0b0b0b" : "#FFDB58",
                color: "#0b0b0b",
              }}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              style={{
                backgroundColor: activeStep === 0 ? "#0b0b0b" : "#FFDB58",
                color: "#0b0b0b",
              }}
              variant={"contained"}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </div>
    );
  } else {
    return (
      <OnboardingPage
        step={steps.info[activeStep - 4]}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setFirstLogin={handleFirstLogin}
        userDetails={userDetails}
        handleDependentCreation={handleDependentCreation}
        closeDependentDialog={closeDependentDialog}
        handleConsent={handleConsent}
        dependents={dependents}
        recordsActions={recordsActions}
        handleAcceptedTc={handleAcceptedTc}
      />
    );
  }
};

export function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails,
    dependents: state.dependents.dependents,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    dependentActions: bindActionCreators(dependentActions, dispatch),
    recordsActions: bindActionCreators(recordsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(OnboardingStyles)(Onboarding));
