import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import { PrivateKey, Transaction } from "@hashgraph/sdk";
import { Buffer } from "buffer";
import { cryptoService } from "./helpers/crypto";
import { sec } from "../../security";
import config from "../../auth.config";
import * as userActions from "../User/UserActions";
import { jwtDecode } from "jwt-decode";

const API_ROOT = process.env.REACT_APP_TOKEN_ENGINE_URL;
const API_KEY = process.env.REACT_APP_TOKEN_ENGINE_API_KEY;
const TOKEN_ID = process.env.REACT_APP_CONSENT_TOKEN_ID;

export const CREATE_USER_ACCOUNT_REQUEST = "CREATE_USER_ACCOUNT_REQUEST";
export const CREATE_USER_ACCOUNT_SUCCESS = "CREATE_USER_ACCOUNT_SUCCESS";
export const CREATE_TOKEN_CONSENT_REQUEST = "CREATE_TOKEN_CONSENT_REQUEST";
export const CREATE_TOKEN_CONSENT_SUCCESS = "CREATE_TOKEN_CONSENT_SUCCESS";
export const CREATE_DATA_CAPTURE_REQUEST = "CREATE_DATA_CAPTURE_REQUEST";
export const CREATE_DATA_CAPTURE_SUCCESS = "CREATE_DATA_CAPTURE_SUCCESS";
export const SEND_INCENTIVE_REQUEST = "SEND_INCENTIVE_REQUEST";
export const SEND_INCENTIVE_SUCCESS = "SEND_INCENTIVE_SUCCESS";
export const GET_INCENTIVE_BALANCE_REQUEST = "GET_INCENTIVE_BALANCE_REQUEST";
export const GET_INCENTIVE_BALANCE_SUCCESS = "GET_INCENTIVE_BALANCE_SUCCESS";
export const REDEEM_INCENTIVE_BALANCE_REQUEST =
  "REDEEM_INCENTIVE_BALANCE_REQUEST";
export const REDEEM_INCENTIVE_BALANCE_SUCCESS =
  "REDEEM_INCENTIVE_BALANCE_SUCCESS";
export const GET_USER_CONSENT_STATUS_REQUEST =
  "GET_USER_CONSENT_STATUS_REQUEST";
export const GET_USER_CONSENT_STATUS_SUCCESS =
  "GET_USER_CONSENT_STATUS_SUCCESS";
export const WITHDRAW_CONSENT_REQUEST = "WITHDRAW_CONSENT_REQUEST";
export const WITHDRAW_CONSENT_SUCCESS = "WITHDRAW_CONSENT_SUCCESS";

export const createUserTokenAccount = (userEmail) => async (dispatch) => {
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  const userSub = jwtDecode(id_token.__raw);

  dispatch({ type: CREATE_USER_ACCOUNT_REQUEST });

  try {
    // 1. Generate Hedera keys locally
    const privateKey = PrivateKey.generateED25519();
    const publicKey = privateKey.publicKey;

    // 2. Create a new user account via API
    const userResponse = await axios({
      method: "POST",
      url: `${API_ROOT}/users`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": API_KEY || "",
      },
      data: {
        publicKey: publicKey.toString(),
        uid: userEmail,
      },
    });

    const { accountId, unsignedTokenAssociateTransaction } = userResponse.data;

    // 3. If there's a token association transaction, sign and submit it
    if (unsignedTokenAssociateTransaction) {
      const buffer = Buffer.from(unsignedTokenAssociateTransaction, "base64");
      const transaction = Transaction.fromBytes(buffer);

      // Sign the transaction
      const signedTx = await transaction.sign(privateKey);
      const signedBytes = signedTx.toBytes();
      const signedBase64 = Buffer.from(signedBytes).toString("base64");

      // 4. Submit the signed token association transaction
      const tokenResponse = await axios({
        method: "POST",
        url: `${API_ROOT}/users/token-association`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-api-key": API_KEY || "",
        },
        data: {
          accountId,
          signedTransaction: signedBase64,
        },
      });

      console.log(
        "Token association transaction submitted successfully",
        tokenResponse.data
      );
    }

    // 5. Encrypt private key and create local user
    const encryptedPrivateKey = await cryptoService.encryptPrivateKey(
      privateKey.toString(),
      userSub.sub
    );

    const responsePayload = {
      ...userResponse.data,
      encryptedPrivateKey,
    };

    console.log("User account created successfully", responsePayload);

    // 5. Dispatch success action
    dispatch({
      type: CREATE_USER_ACCOUNT_SUCCESS,
      payload: responsePayload,
    });
  } catch (error) {
    console.error(
      "Error creating user token account:",
      error?.response?.data?.message || error.message
    );

    dispatch(
      showNotification(
        error?.response?.data?.message ||
          "Oops! Something went wrong. Please try again.",
        "error"
      )
    );
  }
};

export const createConsent = (
  accountId,
  userId,
  consentHash,
  userDetails
) => async (dispatch) => {
  dispatch({ type: CREATE_TOKEN_CONSENT_REQUEST });

  axios({
    method: "POST",
    url: `${API_ROOT}/consent`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-api-key": API_KEY || "",
    },
    data: {
      accountId,
      uid: userId,
      consentHash,
      categoryId: 1,
    },
  })
    .then((response) => {
      console.log("Consent created successfully", response.data);
      dispatch({
        type: CREATE_TOKEN_CONSENT_SUCCESS,
        payload: response.data,
      });

      if (userDetails) {
        const updatedUser = {
          ...userDetails,
          metadata: {
            ...userDetails.metadata,
            consentSerialNumber: response.data.serialNumber,
          },
        };
        dispatch(userActions.updateUser(updatedUser));
      }
    })
    .catch((error) => {
      console.log(
        "There was an issue creating the token consent.",
        error.response.data.message
      );
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const createDataCapture = (accountId, userId, dataHash) => async (
  dispatch
) => {
  dispatch({ type: CREATE_DATA_CAPTURE_REQUEST });

  axios({
    method: "POST",
    url: `${API_ROOT}/data-capture`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-api-key": API_KEY || "",
    },
    data: {
      accountId,
      uid: userId,
      dataHash,
      categoryId: 1,
    },
  })
    .then((response) => {
      console.log("Data capture created successfully", response.data);
      dispatch({
        type: CREATE_DATA_CAPTURE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(
        "There was an issue creating the token consent.",
        error.response.data.message
      );
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const sendIncentive = (accountId, amount, memo) => async (dispatch) => {
  dispatch({ type: SEND_INCENTIVE_REQUEST });

  axios({
    method: "POST",
    url: `${API_ROOT}/incentive/send`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-api-key": API_KEY || "",
    },
    data: {
      accountId,
      amount,
      memo,
    },
  })
    .then((response) => {
      console.log("Incentive successfully sent", response.data);
      dispatch({
        type: SEND_INCENTIVE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(
        "There was an issue sending the incentive",
        error.response.data.message
      );
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getIncentiveBalance = (accountId) => async (dispatch) => {
  dispatch({ type: GET_INCENTIVE_BALANCE_REQUEST });

  axios({
    method: "GET",
    url: `${API_ROOT}/incentive/balance/${accountId}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-api-key": API_KEY || "",
    },
  })
    .then((response) => {
      console.log("Incentive balance received", response.data);
      dispatch({
        type: GET_INCENTIVE_BALANCE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(
        "There was an issue sending the incentive",
        error.response.data.message
      );
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const redeemIncentiveBalance = (
  userAccountId,
  amount,
  memo,
  encryptedKey,
  userBalance
) => async (dispatch) => {
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  const userSub = jwtDecode(id_token.__raw);

  dispatch({ type: REDEEM_INCENTIVE_BALANCE_REQUEST });

  try {
    // 1. Create an unsigned tx for redeeming tokens
    const transactionResponse = await axios({
      method: "POST",
      url: `${API_ROOT}/incentive/redeem`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": API_KEY || "",
      },
      data: {
        accountId: userAccountId,
        amount,
        memo,
      },
    });

    const { accountId, unsignedRedeemTransaction } = transactionResponse.data;

    // 3. If there's a token association transaction, sign and submit it
    if (unsignedRedeemTransaction) {
      const buffer = Buffer.from(unsignedRedeemTransaction, "base64");
      const transaction = Transaction.fromBytes(buffer);
      const decryptedPrivateKey = await cryptoService.decryptPrivateKey(
        encryptedKey,
        userSub.sub
      );
      const privateKey = PrivateKey.fromString(decryptedPrivateKey);

      // Sign the transaction
      const signedTx = await transaction.sign(privateKey);
      const signedBytes = signedTx.toBytes();
      const signedBase64 = Buffer.from(signedBytes).toString("base64");

      // 4. Submit the signed token association transaction
      const submissionResponse = await axios({
        method: "POST",
        url: `${API_ROOT}/incentive/redeem/submit`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-api-key": API_KEY || "",
        },
        data: {
          accountId,
          signedTransaction: signedBase64,
        },
      });

      console.log(
        "Incentive redemption transaction submitted successfully",
        submissionResponse.data
      );

      const newBalance = userBalance - amount;
      // 5. Dispatch success action
      dispatch({
        type: REDEEM_INCENTIVE_BALANCE_SUCCESS,
        payload: {
          balance: newBalance,
        },
      });
    }
  } catch (error) {
    console.error(
      "Error creating user token account:",
      error?.response?.data?.message || error.message
    );

    dispatch(
      showNotification(
        error?.response?.data?.message ||
          "Oops! Something went wrong. Please try again.",
        "error"
      )
    );
  }
};

export const getConsentStatus = (accountId, categoryId) => async (dispatch) => {
  dispatch({ type: GET_USER_CONSENT_STATUS_REQUEST });

  axios({
    method: "GET",
    url: `${API_ROOT}/consent/${TOKEN_ID}/status?accountId=${accountId}&categoryId=${categoryId}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-api-key": API_KEY || "",
    },
  })
    .then((response) => {
      console.log("Consent status received", response.data);
      dispatch({
        type: GET_USER_CONSENT_STATUS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(
        "There was an issue fetching the user consent status",
        error.response.data.message
      );
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const withdrawConsent = (
  userAccountId,
  serialNumber,
  consentHash,
  uid,
  encryptedKey
) => async (dispatch) => {
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  const userSub = jwtDecode(id_token.__raw);

  dispatch({ type: WITHDRAW_CONSENT_REQUEST });

  try {
    // 1. Create an unsigned tx for redeeming tokens
    const transactionResponse = await axios({
      method: "POST",
      url: `${API_ROOT}/consent/withdraw`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": API_KEY || "",
      },
      data: {
        accountId: userAccountId,
        serialNumber,
        consentHash,
        uid,
      },
    });

    console.log("transactionResponse", transactionResponse.data);

    const { accountId, unsignedWithdrawTransaction } = transactionResponse.data;

    // 3. If there's a token association transaction, sign and submit it
    if (unsignedWithdrawTransaction) {
      const buffer = Buffer.from(unsignedWithdrawTransaction, "base64");
      const transaction = Transaction.fromBytes(buffer);
      const decryptedPrivateKey = await cryptoService.decryptPrivateKey(
        encryptedKey,
        userSub.sub
      );
      const privateKey = PrivateKey.fromString(decryptedPrivateKey);

      // Sign the transaction
      const signedTx = await transaction.sign(privateKey);
      const signedBytes = signedTx.toBytes();
      const signedBase64 = Buffer.from(signedBytes).toString("base64");

      // 4. Submit the signed token association transaction
      const submissionResponse = await axios({
        method: "POST",
        url: `${API_ROOT}/consent/withdraw/submit`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-api-key": API_KEY || "",
        },
        data: {
          accountId,
          signedTransaction: signedBase64,
          uid,
        },
      });

      console.log(
        "Consent withdraw transaction submitted successfully",
        submissionResponse.data
      );

      // 5. Dispatch success action
      dispatch({
        type: WITHDRAW_CONSENT_SUCCESS,
        payload: {
          hasConsent: false,
        },
      });
    }
  } catch (error) {
    console.error(
      "Error withdrawing consent",
      error?.response?.data?.message || error.message
    );

    dispatch(
      showNotification(
        error?.response?.data?.message ||
          "Oops! Something went wrong. Please try again.",
        "error"
      )
    );
  }
};
