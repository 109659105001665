import React from "react";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import OnboardingStyles from "../../assets/jss/components/OnboardingStyles";
import { Typography, Button } from "@material-ui/core";

//image
import logo from "../../assets/img/Acoer_Icon.svg";

const AboutPage = (props) => {
  const { classes, step, setActiveStep } = props;

  return (
    <div
      style={{ backgroundColor: `#0b0b0b` }}
      className={
        step.page === 2 ? classes.aboutContainer2 : classes.aboutContainer
      }
    >
      <div
        style={{ backgroundColor: "transparent" }}
        className={classes.aboutLogoContainer}
      >
        <img
          src={logo}
          alt="HealthToken Logo in White"
          className={classes.aboutLogo}
        />
      </div>
      <div
        className={classes.aboutText}
        style={{
          position: "relative",
          top: "8vh",
        }}
      >
        <Typography variant="h4" className={classes.aboutHeader}>
          {step.header}
        </Typography>
        <Typography variant="body1">{step.text}</Typography>
      </div>
      {step.page === 3 && (
        <Button
          variant="contained"
          className={classes.aboutButton}
          onClick={() => setActiveStep(4)}
          style={{
            position: "relative",
            top: "8vh",
          }}
        >
          {step.button}
        </Button>
      )}
    </div>
  );
};

export default withStyles(OnboardingStyles)(AboutPage);
